import React, { useEffect, useState } from "react";
import Image from "next/image";
import Head from "next/head";
import { LoginForm } from "@/forms";
import { getToken } from "@/utils";
import { useRouter } from "next/router";
import { Loading } from "@/components";
import { useStore } from "@/store";

export default function Login() {
  const token = getToken();
  const router = useRouter();
  const [hasToken, setHasToken] = useState(false);
  const [state] = useStore();
  const { user } = state

  useEffect(() => {
    if (token && user) {
      router.push("/chon-chi-nhanh");
    } else {
      setHasToken(true);
    }
  }, [token, router]);

  return (
    <>
      <Head>
        <title>Login</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="shortcut icon" href="/statics/Logo-easysalon.png" />
      </Head>
      {!hasToken ? (
        <Loading />
      ) : (
        <div>
          <div className="flex flex-col items-center mt-[20px]">
            <Image
              src="/statics/Logo.png"
              alt="salon-img"
              width={150}
              height={150}
            />
            <h1 className="mt-[24px] font-bold">ĐĂNG NHẬP</h1>
          </div>

          <div className="w-[796px] h-[105px] mx-auto mt-[37px]">
            <LoginForm />
          </div>
        </div>
      )}
    </>
  );
}
